/* eslint-disable import/no-relative-parent-imports */
// eslint-disable-next-line import/no-unassigned-import
import '../scss/booking-freshcells.scss';
/* eslint-enable import/no-relative-parent-imports */

/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */

interface DestinationData {
    level1: string,
    level2: string,
    city: string,
    regionName: string,
    airportCode: string
}
interface TopResultsItem {
    hotel: {
        name: string,
        board: string
    },
    serviceId: string,
    price: string,
    destination: DestinationData,
    departureAirport: string
}
interface ProductItem {
    serviceId: string,
    name: string,
    price: string,
    units: string,
    category: string,
    destination: DestinationData,
    tourOperator: string,
    hotel: {
        category: string,
        recommendation: string,
        rating: string,
        board: string
    },
    departureAirportCode: string,
    travelPeriod: {
        from: string,
        to: string,
        duration: string
    }
}
interface DataLayerObject {
    page?: {
        pageType: string,
        version: string
    },
    search?: {
        topResults: TopResultsItem[],
        kindOfBooking: string
    },
    products?: ProductItem[],
    basket?: object[],
    checkout?: {
        paymentMethod: string,
        voucher: {
            code: string
        }
    },
    order?: {
        isTestBooking: boolean,
        id: string,
        totalPurchaseValue: string,
        currency: string
    },
    leadConversion?: {
        sha256EmailAddress: string
    }
}

interface Ga4EcommerceItem {
    index: number,
    item_id: string,
    item_name: string,
    price: string,
    quantity: number,
    item_brand?: string,
    item_category?: string, // product type: package or hotel
    item_category2: string, // departure airport code
    item_category3: string, // destination airport code
    item_category4: string, // destination regionName
    item_category5: string, // destination city
    // custom properties
    hotel_category?: string,
    hotel_board?: string,
    hotel_rating?: string,
    hotel_recommendation?: string
}
type Ga4EcommerceItemList = Ga4EcommerceItem[];

interface Ga4EcommerceData {
    transaction_id?: string,
    value: number,
    currency: string,
    items: Ga4EcommerceItemList,
    coupon?: string,
    payment_type?: string
}

declare global {
    interface Window {
        pageData: DataLayerObject,
        dataLayer: object[],
    }
}

window.pageData = {};
window.dataLayer = window.dataLayer || [];

/**
 *  Returns the device size of the screen.
 *
 *  @return {string} l(arge), m(edium) or s(mall)
 */
function getMedia (): string {
    let breakpoint = window.matchMedia('(min-width: 640px)');
    if (!breakpoint || !breakpoint.matches) {
        return 's';
    }
    breakpoint = window.matchMedia('(min-width: 960px)');
    if (!breakpoint || !breakpoint.matches) {
        return 'm';
    }
    return 'l';
}

/**
 * Returns an ecommerce object as defined by Google.
 *
 * @param products The products array as provided by the Freshcells IBE events.
 * @param kindOfBooking The IBE type, package or hotel.
 */
function getGa4EcommerceData (products: ProductItem[], kindOfBooking: string): Ga4EcommerceData {
    const items = <Ga4EcommerceItemList>[];
    let totalValue = 0;
    products.forEach((item:ProductItem, index) => {
        items.push({
            index,
            item_id: item.serviceId,
            item_name: item.name,
            price: item.price,
            quantity: 1,
            item_brand: item.tourOperator,
            item_category: kindOfBooking,
            item_category2: item.departureAirportCode,
            item_category3: item.destination.airportCode,
            item_category4: item.destination.regionName,
            item_category5: item.destination.city,
            // custom props
            hotel_category: item.hotel.category,
            hotel_board: item.hotel.board,
            hotel_rating: item.hotel.rating,
            hotel_recommendation: item.hotel.recommendation
        });
        totalValue += parseInt(item.price);
    });
    return { value: totalValue, currency: 'CHF', items };
}

function workOnDom (): void {
    window.addEventListener('pageView', function (e) {
        const customEvent = e as CustomEvent;
        // assign to DL
        window.pageData.page = Object.assign({}, customEvent.detail.page);
        window.pageData.search = Object.assign({}, customEvent.detail.search);

        if (typeof window.pageData.page !== 'undefined') {
            if (typeof getMedia === 'function') {
                window.pageData.page.version = getMedia();
            }

            // no page view on step "booking-process"
            if (typeof window.pageData.page.pageType !== 'undefined') {
                switch (window.pageData.page.pageType) {
                    case 'destinations':
                        delete window.pageData.products;
                        break;
                    case 'productdetail':
                        delete window.pageData.basket;
                        delete window.pageData.checkout;
                        delete window.pageData.order;
                        break;
                    case 'booking-process':
                        return;
                }
            }
        }

        /* push pageView event to GTM data layer */
        const pageViewEventData = {
            event: 'pageView',
            page: Object.assign({}, customEvent.detail.page),
            search: { }
        };
        if (typeof customEvent.detail.search !== 'undefined') {
            pageViewEventData.search = Object.assign({}, customEvent.detail.search);
        }
        window.dataLayer.push(pageViewEventData);

        // for adobe launch
        if (typeof CustomEvent === 'function') {
            window.dispatchEvent(new CustomEvent('pageViewWithData', { }));
        }
    });

    window.addEventListener('searchUpdate', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.search = Object.assign({}, customEvent.detail.search);
    });

    window.addEventListener('searchResultsLoaded', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.search = Object.assign({}, customEvent.detail.search);
    });

    window.addEventListener('accordionGroupExpand', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.page = Object.assign({}, customEvent.detail.page);
        window.pageData.search = Object.assign({}, customEvent.detail.search);
    });

    window.addEventListener('accordionGroupCollapse', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.page = Object.assign({}, customEvent.detail.page);
    });

    window.addEventListener('productImpression', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.search = Object.assign({}, customEvent.detail.search);

        const dlData = <DataLayerObject>customEvent.detail;
        if (typeof dlData.search !== 'undefined' && typeof dlData.search.topResults !== 'undefined') {
            const items = <Ga4EcommerceItemList>[];
            dlData.search.topResults.forEach((item:TopResultsItem, index) => {
                items.push({
                    index,
                    item_id: item.serviceId,
                    item_name: item.hotel.name,
                    price: item.price,
                    quantity: 1,
                    item_category: dlData.search?.kindOfBooking,
                    item_category2: item.departureAirport,
                    item_category3: item.destination.airportCode,
                    item_category4: item.destination.regionName,
                    item_category5: item.destination.city,
                    hotel_board: item.hotel.board
                });
            });
            if (items.length > 0) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: {
                        item_list_id: 'search_results',
                        item_list_name: 'Search Results',
                        items
                    }
                });
            }
        }
    });

    window.addEventListener('productDetail', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.products = Object.assign({}, customEvent.detail.products);

        const dlData = <DataLayerObject>customEvent.detail;
        if (typeof dlData.products !== 'undefined' && Array.isArray(dlData.products) && dlData.products.length > 0) {
            const kindOfBooking = dlData.search?.kindOfBooking || '';
            const ecommerce = getGa4EcommerceData(dlData.products, kindOfBooking);
            if (ecommerce.items.length > 0) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: 'view_item',
                    ecommerce
                });
            }
        }
    });

    window.addEventListener('bookingCartLoaded', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.products = Object.assign({}, customEvent.detail.products);
        if (typeof customEvent.detail.checkout !== 'undefined') {
            window.pageData.checkout = Object.assign({}, customEvent.detail.checkout);
        }
        if (typeof customEvent.detail.basket !== 'undefined') {
            window.pageData.basket = Object.assign({}, customEvent.detail.basket);
        }
        if (typeof customEvent.detail.order !== 'undefined') {
            window.pageData.order = Object.assign({}, customEvent.detail.order);
        }

        let dlData = null;
        // based on global pageData value (updated during the pageView event)
        if (typeof window.pageData.page !== 'undefined') {
            switch (window.pageData.page.pageType) {
                case 'booking-travellers':
                    dlData = <DataLayerObject>customEvent.detail;
                    if (typeof dlData.products !== 'undefined' && Array.isArray(dlData.products) && dlData.products.length > 0) {
                        const ecommerce = getGa4EcommerceData(dlData.products, dlData.products[0].category);
                        if (ecommerce.items.length > 0) {
                            window.dataLayer.push({ ecommerce: null });
                            window.dataLayer.push({
                                event: 'add_to_cart',
                                ecommerce
                            });
                        }
                    }
                    break;

                case 'booking-payment':
                    dlData = <DataLayerObject>customEvent.detail;
                    if (typeof dlData.products !== 'undefined' && Array.isArray(dlData.products) && dlData.products.length > 0) {
                        const ecommerce = getGa4EcommerceData(dlData.products, dlData.products[0].category);
                        if (ecommerce.items.length > 0) {
                            window.dataLayer.push({ ecommerce: null });
                            window.dataLayer.push({
                                event: 'begin_checkout',
                                ecommerce
                            });
                        }
                    }
                    break;

                case 'booking-process':
                    break;
                case 'booking-card-payment':
                    break;
                case 'booking-failure':
                    break;

                case 'booking-confirmation':
                    dlData = <DataLayerObject>customEvent.detail;
                    if (typeof dlData.products !== 'undefined' && typeof dlData.order !== 'undefined') {
                        // skip if isTestBooking
                        if (typeof dlData.order.isTestBooking !== 'undefined' && dlData.order.isTestBooking) {
                            break;
                        }
                        const ecommerce = getGa4EcommerceData(dlData.products, dlData.products[0].category);

                        // set additional data and overwrite data
                        ecommerce.transaction_id = dlData.order.id;
                        ecommerce.currency = dlData.order.currency;
                        ecommerce.value = parseInt(dlData.order.totalPurchaseValue);
                        if (typeof dlData.checkout !== 'undefined' && typeof dlData.checkout.voucher !== 'undefined') {
                            ecommerce.coupon = dlData.checkout.voucher.code;
                        }

                        if (ecommerce.items.length > 0) {
                            window.dataLayer.push({ ecommerce: null });
                            window.dataLayer.push({
                                event: 'purchase',
                                ecommerce
                            });
                        }
                    }
                    break;
            }
        }
    });

    window.addEventListener('availabilityCheck', function (e) {
        const customEvent = e as CustomEvent;
        if (typeof customEvent.detail !== 'undefined' && typeof customEvent.detail.products !== 'undefined') {
            window.pageData.products = Object.assign({}, customEvent.detail.products);

            if (customEvent.detail.products.length >= 1
                && customEvent.detail.products[0].availability !== 'undefined'
                && customEvent.detail.products[0].tourOperator !== 'undefined') {
                if (customEvent.detail.products[0].availability === 'ok') {
                    window.dataLayer.push({
                        event: 'check_availability',
                        custom_event_data: {
                            item_availability: 'Available',
                            item_brand: customEvent.detail.products[0].tourOperator,
                            item_name: customEvent.detail.products[0].name,
                            item_id: customEvent.detail.products[0].id
                        }
                    });
                } else {
                    window.dataLayer.push({
                        event: 'check_availability',
                        custom_event_data: {
                            item_availability: 'NotAvailable',
                            item_brand: customEvent.detail.products[0].tourOperator,
                            item_name: customEvent.detail.products[0].name,
                            item_id: customEvent.detail.products[0].id
                        }
                    });
                }
            }
        }
    });

    window.addEventListener('favoritesLoaded', function (e) {
        const customEvent = e as CustomEvent;
        window.pageData.products = Object.assign({}, customEvent.detail.products);
    });

    window.addEventListener('final', function () {
        if (typeof CustomEvent === 'function') {
            window.dispatchEvent(new CustomEvent('pageDataLoaded', { }));
        }
        window.dataLayer.push({
            event: 'final'
        });
    });

    window.addEventListener('hotelNotFound', function () {
        // additional custom event
        window.dataLayer.push({
            event: 'filter_results',
            custom_event_data: {
                filter_error: 'HotelNotFound',
                page_location: window.location.href
            }
        });
    });

    window.addEventListener('offerNotFound', function () {
        // additional custom event
        window.dataLayer.push({
            event: 'filter_results',
            custom_event_data: {
                filter_error: 'OfferNotFound',
                page_location: window.location.href
            }
        });
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}

/* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
